import * as React from 'react';
import {render} from 'react-dom';
import {Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import Routes from '@Components/Routes';
import history from '@Components/History';
import store from './store';
import * as Styles from './styles.scss';
Styles.initialize;
render(
    <Provider store={store}>
        <Router history={history}>
            <Routes />
        </Router>
    </Provider>,
    document.getElementById('root')
);