// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:'San Francisco';font-weight:400;src:url(\"https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff\")}html{height:100%}body{background-color:#f5c6cd;font-family:'San Francisco';margin:0;padding:0;height:100%;background-image:url(\"https://storage.googleapis.com/sinclair-digital-snapps-public/bg-mobile.png\");background-repeat:no-repeat;background-size:100% 100%;background-attachment:fixed}@media (min-width: 787px){body{background-image:url(\"https://storage.googleapis.com/sinclair-digital-snapps-public/bg-desktop.png\")}}@media (min-width: 787px){#root{margin-top:7%;margin:auto;margin-top:0;max-width:1024px;display:flex;flex:1;height:100%}}.styles_initialize__564ce{color:white}\n", ""]);
// Exports
exports.locals = {
	"initialize": "styles_initialize__564ce"
};
module.exports = exports;
