// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_ListItemBestStreak__38513{display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin-right:3%;margin-left:3%;margin-top:3%;background-color:#fff;height:70px;border-radius:35px;color:#333D73;font-weight:bold;position:relative;box-shadow:0 2px 5px 0 rgba(11,14,32,0.4)}.styles_ListItemBestStreak__38513:first-of-type{background-color:#EA667A;color:#fff}.styles_ListItemBestStreak__38513:first-of-type .styles_place__90704{color:#fff}.styles_ListItemBestStreak__38513:first-of-type .styles_best__495cd{background-color:#fff;color:#EA667A}.styles_ListItemBestStreak__38513:nth-of-type(2){background-color:#f093a1}.styles_ListItemBestStreak__38513:nth-of-type(3){background-color:#f6c0c8}@media (min-width: 787px){.styles_ListItemBestStreak__38513{margin-top:1.5%}}.styles_left__d7bd5,.styles_right__49fde{display:flex;flex-direction:row;align-items:center}.styles_left__d7bd5{justify-content:flex-start;flex-grow:3}.styles_right__49fde{justify-content:flex-end;flex-grow:1}.styles_place__90704{font-size:1.7rem;margin:20px 10px 20px 20px;color:#8c96cc}.styles_displayName__ef420{font-size:0.8rem}@media (min-width: 787px){.styles_displayName__ef420{font-size:1.5rem;margin-left:5%}}.styles_labeled__83e85 div:first-of-type{font-size:0.65rem}.styles_total__17c7e{margin-right:6%}.styles_total__17c7e>.styles_totalValue__e9fea{font-size:1.2rem}.styles_best__495cd{box-sizing:border-box;height:70px;min-width:70px;padding-right:10px;padding-left:10px;border-radius:35px;background-color:#8c96cc;color:#fff;display:flex;flex-direction:column;justify-content:center}.styles_best__495cd>div{text-align:center}.styles_best__495cd .styles_bestValue__ad3b0{font-size:1.6rem}\n", ""]);
// Exports
exports.locals = {
	"ListItemBestStreak": "styles_ListItemBestStreak__38513",
	"place": "styles_place__90704",
	"best": "styles_best__495cd",
	"left": "styles_left__d7bd5",
	"right": "styles_right__49fde",
	"displayName": "styles_displayName__ef420",
	"labeled": "styles_labeled__83e85",
	"total": "styles_total__17c7e",
	"totalValue": "styles_totalValue__e9fea",
	"bestValue": "styles_bestValue__ad3b0"
};
module.exports = exports;
