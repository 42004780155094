import {createNamespacedConstFactory} from '@Utils/misc';
import {Score} from '@Root/models/Score';
export const NAMESPACE = '@Scoarboard';
const cc = createNamespacedConstFactory(NAMESPACE);

export interface State {
    fetchedOnce: boolean;
    isFetching: boolean;
    scores: Score[];
    lastUpdated: Date;
}

export const DEFAULT_STATE: State = {
    fetchedOnce: false,
    isFetching: false,
    scores: [],
    lastUpdated: new Date(),
};

export const SET_IS_FETCHING = cc('SET_IS_FETCHING');
export const SET_SCORES = cc('SET_SCORES');
export const SET_LAST_UPDATED = cc('SET_LAST_UPDATED');