import * as React from 'react';
import {connect} from 'react-redux';
import * as styles from './styles.scss';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import AppState from '@AppState';
import {
    Score,
    selectDisplayName,
    selectTotal,
    selectBest,
} from '@Root/models/Score';
import {
    selectScores,
    selectLastUpdated,
    fetchScoreboard,
    selectFechedOnce,
} from '@Root/data/Scoreboard';
import ListMarquee from '@Components/ListMarquee';
import ListItemBestStreak from '@Root/components/ListItemBestStreak';
import * as uuid from 'uuid/v4';
import {noop} from 'lodash';
import {formatDistanceToNow} from 'date-fns';

export interface Props {
    scores: Score[];
    lastUpdated: Date;
    fetchScoreboard: typeof fetchScoreboard;
    animateable: boolean;
}

export interface State {
    animateable: boolean;
    updated: number;
}

const renderer = (item: Score, index: number): React.ReactElement => {
    return (
        <ListItemBestStreak
            key={`ListItemBestStreak-${uuid()}-${index}`}
            place={index + 1}
            displayName={selectDisplayName(item)}
            total={selectTotal(item)}
            best={selectBest(item)}
        />
    );
};

class Scoreboard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.props.fetchScoreboard(__CONFIG__.SCOREBOARD.DATES);
        this.ref = React.createRef();
        this.state = {
            animateable: false,
            updated: Date.now(),
        };
    }

    private animationInterval = setInterval(noop, Number.MAX_SAFE_INTEGER);
    private animation2Interval = setInterval(noop, Number.MAX_SAFE_INTEGER);
    private updatingInterval = setInterval(noop, Number.MAX_SAFE_INTEGER);
    
    private ref: React.RefObject<HTMLDivElement>;

    private startAnimating = (): void => {
        let atEnd = false,
            direction = false, // false === down
            lastPos = 0,
            pos = 0,
            wait = 200,
            lastScroll = 0,
            scrolling = false,
            timeout: NodeJS.Timeout;
        this.animationInterval = setInterval((): void => {
            const {animateable} = this.state;
            if (this.ref.current && this.ref.current.clientWidth >= 786 && animateable) {
                requestAnimationFrame((): void => {
                    if (this.props.animateable && !scrolling) {
                        if (atEnd) {
                            direction = !direction;
                            atEnd = false;
                        }
                        lastScroll = window.scrollY;
                        pos = window.scrollY + (direction ? -1 : 1);
                        window.scrollTo(0, pos);
                        if (lastPos === pos && wait) {
                            wait--;
                        }
                        if (!wait) {
                            direction = !direction;
                            wait = 200;
                        }
                        lastPos = pos;
                    }
                });
            }
        }, 10);

        document.body.onscroll = (): void => {
            const diff = lastScroll - window.scrollY;
            clearTimeout(timeout);
            if (Math.abs(diff) > 1 && !scrolling) {
                scrolling = true;
                direction = diff > 0;
            }
            timeout = setTimeout(() => scrolling = false, 1000);
        };
    }

    public componentDidMount(): void {
        this.updatingInterval = setInterval((): void => {
            this.props.fetchScoreboard(__CONFIG__.SCOREBOARD.DATES);
        }, 180000, __CONFIG__.SCOREBOARD.UPDATE_INTERVAL);

        this.animation2Interval = setInterval((): void => {
            this.setState({updated: Date.now()});
        }, 15000);
    }

    private setIsTallerThanWindow = (animateable: boolean): void => {
        this.setState({animateable});
    }

    public componentWillUnmount(): void {
        clearInterval(this.animationInterval);
        clearInterval(this.animation2Interval);
        clearInterval(this.updatingInterval);
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps.animateable === false && this.props.animateable === true) {
            this.startAnimating();
        }
    }

    public render(): React.ReactElement {
        const {
            scores,
            lastUpdated,
        } = this.props;
        return (
            <div className={styles.Scoreboard} ref={this.ref}>
                <div className={styles.branding}>
                    <img className={styles.logo} src={__CONFIG__.ASSETS.LOGO} />
                    <div className={styles.logoTextWrapper}>
                        <img className={styles.logoText} src={__CONFIG__.ASSETS.LOGO_TEXT} />
                        <div className={styles.updatedDate}>Updated: {formatDistanceToNow(lastUpdated, {includeSeconds: true})} ago.</div>
                    </div>
                </div>
                <div className={styles.spacer} />
                <div className={styles.list}>
                    <div id="top" />
                    {   
                        scores.length &&
                        <ListMarquee<Score>
                            items={scores}
                            renderer={renderer}
                            setIsTallerThanWindow={this.setIsTallerThanWindow}
                        />
                    }
                    <div id="bottom" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState): {
    scores: Score[];
    lastUpdated: Date;
    animateable: boolean;
} => ({
    scores: selectScores(state),
    lastUpdated: selectLastUpdated(state),
    animateable: selectFechedOnce(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    fetchScoreboard,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Scoreboard);