import {
    createStore,
    applyMiddleware,
} from 'redux';
import reducer from './reducer';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

/* istanbul ignore next */
const composeWithEnhancers = composeWithDevTools({
    maxAge: __CONFIG__.REDUX_DEVTOOLS_MAX_AGE,
});

export default createStore(reducer, {}, composeWithEnhancers(applyMiddleware(thunk)));