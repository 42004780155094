import {
    State,
    DEFAULT_STATE,
    SET_IS_FETCHING,
    SET_SCORES,
    SET_LAST_UPDATED,
} from './constants';
import ActionTypes from '@ActionTypes';
import {Score} from '@Root/models/Score';

const reducer = (state: State = DEFAULT_STATE, {type, payload}: ActionTypes): State => {
    let newState = state;
    switch(type) {
        case SET_IS_FETCHING:
            newState = {
                ...state,
                isFetching: payload as boolean
            };
            break;
        case SET_SCORES:
            newState = {
                ...state,
                fetchedOnce: true,
                scores: payload as Score[],
            };
            break;
        case SET_LAST_UPDATED:
            newState = {
                ...state,
                lastUpdated: payload as Date,
            };
    }
    return newState;
};

export default reducer;