// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_Scoreboard__e5d37{display:flex;flex:1;flex-direction:column;padding:4%;box-sizing:border-box}@media (min-width: 787px){.styles_Scoreboard__e5d37{flex-direction:row-reverse;height:100%}}.styles_branding__87b30{display:flex;flex:1;justify-content:center;flex-direction:row;box-sizing:border-box;position:relative;max-height:200px}@media (min-width: 787px){.styles_branding__87b30{position:fixed;display:block;justify-content:center;text-align:center;width:262px;top:calc(50% - 132px);max-height:unset}}.styles_logo__1d408{width:25%}@media (min-width: 787px){.styles_logo__1d408{width:75%}}.styles_logoTextWrapper__2d311{width:40%;padding-left:5%;display:flex;flex-direction:column;justify-content:center}@media (min-width: 787px){.styles_logoTextWrapper__2d311{margin-top:4%;margin-left:0;width:100%;padding-left:0}}.styles_logoText__e95d2{width:100%}.styles_list__bd495{margin-top:5%}@media (min-width: 787px){.styles_list__bd495{flex:1;margin-top:0;flex-grow:2}.styles_list__bd495>div{margin-bottom:4%}}.styles_spacer__3870c{content:' ';display:none}@media (min-width: 787px){.styles_spacer__3870c{display:flex;flex:1;justify-content:center;flex-grow:1;align-self:flex-start;flex-direction:column;align-items:center;box-sizing:border-box;padding-right:4%;padding-left:4%;height:100%}}.styles_updatedDate__c26bf{color:#333D73;position:absolute;bottom:0;font-size:11px}@media (min-width: 787px){.styles_updatedDate__c26bf{position:fixed;bottom:5px;right:5px;top:unset}}\n", ""]);
// Exports
exports.locals = {
	"Scoreboard": "styles_Scoreboard__e5d37",
	"branding": "styles_branding__87b30",
	"logo": "styles_logo__1d408",
	"logoTextWrapper": "styles_logoTextWrapper__2d311",
	"logoText": "styles_logoText__e95d2",
	"list": "styles_list__bd495",
	"spacer": "styles_spacer__3870c",
	"updatedDate": "styles_updatedDate__c26bf"
};
module.exports = exports;
