import {
    combineReducers
} from 'redux';
import {
    reducer as Scoreboard,
    NAMESPACE as ScoreboardNS,
} from '@Data/Scoreboard';

/* istanbul ignore next */
const reducers = {
    [ScoreboardNS]: Scoreboard,
};

export default combineReducers(reducers);