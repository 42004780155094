import * as React from 'react';
import {Switch, Route} from 'react-router-dom';
import Scoarboard from '@Screens/Scoreboard';

const Routes: React.FunctionComponent = (): JSX.Element => {
    return (
        <Switch>
            <Route exact path="/" component={Scoarboard} />
        </Switch>
    );
};
export default Routes;