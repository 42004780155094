import * as React from 'react';
import * as styles from './styles.scss';
import cn from 'classnames';

interface Props {
    place: number;
    displayName: string;
    total: number;
    best: number;
}


const ListItemBestStreak: React.FC<Props> = ({
    place,
    displayName,
    total,
    best,
}): React.ReactElement => {
    return (
        <div className={styles.ListItemBestStreak}>
            <div className={styles.left}>
                <div className={styles.place}>{place}</div>
                <div className={styles.displayName}>{displayName}</div>
            </div>
            <div className={styles.right}>
                <div className={cn(styles.labeled, styles.total)}>
                    <div>Stories Read</div>
                    <div className={styles.totalValue}>{total}</div>
                </div>
                <div className={cn(styles.labeled, styles.best)}>
                    <div>Best Streak</div>
                    <div className={styles.bestValue}>{best}</div>
                </div>
            </div>
        </div>
    );
};

export default ListItemBestStreak;