// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_ListMarquee__eaf43{width:100%;display:flex;flex-direction:column;border-radius:16.94px;background:linear-gradient(180deg, #ECECEC 0%, #AFB6D9 100%);box-shadow:-5px 9px 19px 0 rgba(22,29,64,0.5);padding-top:10px;padding-bottom:10px;transition-property:margin-top;transition-timing-function:linear}\n", ""]);
// Exports
exports.locals = {
	"ListMarquee": "styles_ListMarquee__eaf43"
};
module.exports = exports;
