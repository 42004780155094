import * as React from 'React';
import * as styles from './styles.scss';
import cn from 'classnames';

interface Props<T> extends React.HTMLProps<HTMLDivElement> {
    items: T[];
    renderer: (item: T, key: number) => React.ReactElement;
    setIsTallerThanWindow?: (b: boolean) => void;
}

interface State {
    tallerThanWindow: boolean;
    ref: React.RefObject<HTMLDivElement>;
}

class ListMarquee<T> extends React.Component<Props<T>, State> {
    public state = {
        tallerThanWindow: false,
        ref: React.createRef<HTMLDivElement>(),
    }

    public componentDidUpdate(prevProps: Props<T>, prevState: State): void {
        const {setIsTallerThanWindow} = this.props;
        const {
            tallerThanWindow,
            ref,
        } = prevState;
        const tallerThanWindowCur = Boolean(ref && ref.current && ref.current.clientHeight >= window.innerHeight);

        if (tallerThanWindowCur !== tallerThanWindow) {
            this.setState({tallerThanWindow: tallerThanWindowCur}, () => {
                if (setIsTallerThanWindow) {
                    setIsTallerThanWindow(tallerThanWindowCur);
                }
            });
        }
    }

    render(): React.ReactElement {
        const {
            items,
            renderer,
            className,
        } = this.props;

        return (
            <div className={cn(styles.ListMarquee, className)} ref={this.state.ref}>
                {items.map((item, index) => renderer(item, index))}
            </div>
        );
    };
}

export default ListMarquee;