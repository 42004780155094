import {
    createAction,
    ActionType,
} from 'typesafe-actions';
import {
    SET_IS_FETCHING,
    SET_SCORES,
    SET_LAST_UPDATED,
} from './constants';
import {
    Score,
    ResponseScoreboard,
    selectBest,
} from '@Root/models/Score';
import {
    AnyAction,
    Dispatch,
} from 'redux';
import Axios, {
    AxiosRequestConfig,
} from 'axios';

export const setIsFetching = createAction(SET_IS_FETCHING)<boolean>();
export const setScores = createAction(SET_SCORES)<Score[]>();
export const setLastUpdated = createAction(SET_LAST_UPDATED)<Date>();

export const fetchScoreboard = ({start, end}: {start: string; end: string}) => async (dispatch: Dispatch<AnyAction>): Promise<Score[]> => {
    dispatch(setIsFetching(true));
    const params: AxiosRequestConfig = {
        url: `${__CONFIG__.API_BASE_URL}${__CONFIG__.ENDPOINTS.scoreboard}`,
        method: 'get',
        params: {
            start,
            end,
        },
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
        }
    };
    const {data: {
        lastUpdated,
        scoreboard: scores,
    }} = await Axios.request<ResponseScoreboard>(params);
    dispatch(setScores(scores.filter((score): boolean => Boolean(selectBest(score)))));
    dispatch(setLastUpdated(new Date(lastUpdated)));
    dispatch(setIsFetching(false));
    return scores;
};

const actions = {
    setIsFetching,
    setScores,
    setLastUpdated,
};

export type ActionTypes = ActionType<typeof actions>;